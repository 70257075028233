<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div row wrap class="pui-form-layout">
				<pui-field-set :title="$t('pmprovider.title')">
					<v-layout wrap>
						<v-flex xs12 :md6="!isSuperAdmin" :md4="isSuperAdmin">
							<pui-text-field :label="$t('pmprovider.name')" v-model="model.name" required toplabel maxlength="150"></pui-text-field>
						</v-flex>
						<v-flex xs12 :md6="!isSuperAdmin" :md4="isSuperAdmin">
							<pui-text-field
								v-model="model.acronym"
								:label="$t('pmprovider.acronym')"
								maxlength="10"
								required
								toplabel
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md4 v-if="isSuperAdmin">
							<pui-select
								attach="pmorganizationid"
								:label="this.$t('pmprovider.organization')"
								:placeholder="this.$t('pmprovider.phorganization')"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="vluppmorganization"
								:modelFormMapping="{ pmorganizationid: 'pmorganizationid' }"
								:itemsToSelect="organizationItemsToSelect"
								itemValue="pmorganizationid"
								itemText="name"
							></pui-select>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12>
							<pui-text-area v-model="model.description" :label="$t('pmprovider.description')" maxlength="250" toplabel></pui-text-area>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md4>
							<pui-checkbox
								:label="$t('pmprovider.disabled')"
								v-model="model.disabled"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
					</v-layout>
					<pui-field-set :title="$t('pmprovider.providerinfo')">
						<v-layout wrap>
							<v-flex xs12 md3>
								<pui-text-field v-model="model.country" :label="$t('pmprovider.country')" maxlength="50" toplabel></pui-text-field>
							</v-flex>
							<v-flex xs12 md3>
								<pui-text-field v-model="model.city" :label="$t('pmprovider.city')" maxlength="50" toplabel></pui-text-field>
							</v-flex>
							<v-flex xs12 md3>
								<pui-text-field
									v-model.number="model.postalcode"
									:label="$t('pmprovider.postalcode')"
									maxlength="10"
									toplabel
								></pui-text-field>
							</v-flex>
							<v-flex xs12 md3>
								<pui-text-field :label="$t('pmprovider.address')" v-model="model.address" toplabel maxlength="100"></pui-text-field>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<v-flex xs12 md4>
								<pui-text-field v-model="model.url" :label="$t('pmprovider.url')" maxlength="250" toplabel></pui-text-field>
							</v-flex>
							<v-flex xs12 md4>
								<pui-text-field v-model.number="model.phone" :label="$t('pmprovider.phone')" maxlength="10" toplabel></pui-text-field>
							</v-flex>
							<v-flex xs12 md4>
								<pui-text-field v-model="model.email" :label="$t('pmprovider.email')" maxlength="100" toplabel></pui-text-field>
							</v-flex>
						</v-layout>
					</pui-field-set>
					<pui-field-set :title="$t('pmprovider.providercontact')">
						<v-layout wrap>
							<v-flex xs12 md4>
								<pui-text-field
									v-model="model.contactname"
									:label="$t('pmprovider.contactname')"
									maxlength="250"
									toplabel
								></pui-text-field>
							</v-flex>
							<v-flex xs12 md4>
								<pui-text-field
									v-model="model.contactrole"
									:label="$t('pmprovider.contactrole')"
									maxlength="250"
									toplabel
								></pui-text-field>
							</v-flex>
							<v-flex xs12 md4>
								<pui-text-field
									v-model="model.contactemail"
									:label="$t('pmprovider.contactemail')"
									maxlength="100"
									toplabel
								></pui-text-field>
							</v-flex>
						</v-layout>
					</pui-field-set>
				</pui-field-set>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import { isSuperAdmin } from '@/api/common';

export default {
	name: 'pmproviderform',
	mixins: [PuiFormMethodsMixin],

	data() {
		return {
			modelName: 'pmprovider',
			model: 'pmprovider',
			isSuperAdmin: false
		};
	},
	computed: {
		organizationItemsToSelect() {
			return [{ pmorganizationid: this.model.pmorganizationid }];
		}
	},
	beforeCreate() {
		if (this.model) {
			this.modelLoaded = true;
		}
	},
	created() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
	},
	updated() {
		if (this.model) {
			if (!isSuperAdmin(this.session.profiles[0])) {
				this.model.pmorganizationid = this.userProperties['organizationid'];
			}
		}
	},
	methods: {}
};
</script>
